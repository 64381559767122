import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import Layout2 from '../views/layout/Layout2'
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: '/welcome',
    children: [{
      path: 'welcome',
      name: 'welcome',
      component: () => import('@/views/home/welcome'),
      meta: { title: '欢迎页', icon: 'home' }
    }]
  }
]

export const asyncRouterMap = [
  // 权限管理
  {
    path: '/ums',
    component: Layout,
    redirect: '/ums/admin',
    name: 'ums',
    meta: { title: '权限', icon: 'ums' },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/ums/admin/index'),
        meta: { title: '用户列表', icon: 'ums-admin' }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/ums/role/index'),
        meta: { title: '角色列表', icon: 'ums-role' }
      },
      {
        path: 'allocMenu',
        name: 'allocMenu',
        component: () => import('@/views/ums/role/allocMenu'),
        meta: { title: '分配菜单' },
        hidden: true
      },
      {
        path: 'allocResource',
        name: 'allocResource',
        component: () => import('@/views/ums/role/allocResource'),
        meta: { title: '分配资源' },
        hidden: true
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/ums/menu/index'),
        meta: { title: '菜单列表', icon: 'ums-menu' }
      },
      {
        path: 'addMenu',
        name: 'addMenu',
        component: () => import('@/views/ums/menu/add'),
        meta: { title: '添加菜单' },
        hidden: true
      },
      {
        path: 'updateMenu',
        name: 'updateMenu',
        component: () => import('@/views/ums/menu/update'),
        meta: { title: '修改菜单' },
        hidden: true
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/views/ums/resource/index'),
        meta: { title: '资源列表', icon: 'ums-resource' }
      },
      {
        path: 'resourceCategory',
        name: 'resourceCategory',
        component: () => import('@/views/ums/resource/categoryList'),
        meta: { title: '资源分类' },
        hidden: true
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/ums/dictionary/index'),
        meta: { title: '字典管理', icon: 'dictionary' }
      },
      {
        path: 'bannedList',
        name: 'bannedList',
        component: () => import('@/views/ums/bannedList/index'),
        meta: { title: '封禁列表', icon: 'banned' }
      },
      {
        path: 'securityPolicy',
        name: 'securityPolicy',
        component: () => import('@/views/ums/bannedList/securityPolicy'),
        meta: { title: '安全策略' },
        hidden: true
      },
      {
        path: 'logList',
        name: 'logList',
        component: () => import('@/views/ums/logList/index'),
        meta: { title: '封禁列表', icon: 'log-list' }
      },
    ]
  },
  // 商城管理
  {
    path: '/agrochemicalMall',
    component: Layout,
    redirect: '/agrochemicalMall/mallInformation',
    name: 'agrochemicalMall',
    meta: { title: '农业商城管理', icon: 'agrochemical' },
    children: [
      {
        path: 'mallInformation',
        name: 'mallInformation',
        component: () => import('@/views/agrochemicalMall/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addInformation',
        name: 'addInformation',
        component: () => import('@/views/agrochemicalMall/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateInformation',
        name: 'updateInformation',
        component: () => import('@/views/agrochemicalMall/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/agrochemicalMall/information/classification_management.vue'),
        meta: { title: '分类管理', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallUserList',
        name: 'mallUserList',
        component: () => import('@/views/agrochemicalMall/userList/index.vue'),
        meta: { title: '用户列表', icon: 'user_list' }
      },
      {
        path: 'mallTeamList',
        name: 'mallTeamList',
        component: () => import('@/views/agrochemicalMall/userList/team_management.vue'),
        meta: { title: '团队列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallIntegralList',
        name: 'mallIntegralList',
        component: () => import('@/views/agrochemicalMall/userList/integralList.vue'),
        meta: { title: '积分统计列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallOrder',
        name: 'mallOrder',
        component: () => import('@/views/agrochemicalMall/order/index.vue'),
        meta: { title: '订单列表', icon: 'order' }
      },
      {
        path: 'mallOrderDetail',
        name: 'mallOrderDetail',
        component: () => import('@/views/agrochemicalMall/order/orderDetail.vue'),
        meta: { title: '订单详情', icon: 'order' },
        hidden: true
      },
      {
        path: 'mallOrderRefund',
        name: 'mallOrderRefund',
        component: () => import('@/views/agrochemicalMall/orderRefund/index.vue'),
        meta: { title: '退款申请列表', icon: 'orderRefund' }
      },
      {
        path: 'mallProduct',
        name: 'mallProduct',
        component: () => import('@/views/agrochemicalMall/product/index.vue'),
        meta: { title: '商品列表', icon: 'product' }
      },
      {
        path: 'mallProductGiftList',
        name: 'mallProductGiftList',
        component: () => import('@/views/agrochemicalMall/product/giftList.vue'),
        meta: { title: '赠品列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'mallProductSkuList',
        name: 'mallProductSkuList',
        component: () => import('@/views/agrochemicalMall/product/skuList.vue'),
        meta: { title: 'sku列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'addMallProduct',
        name: 'addMallProduct',
        component: () => import('@/views/agrochemicalMall/product/add.vue'),
        meta: { title: '添加商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallProduct',
        name: 'updateMallProduct',
        component: () => import('@/views/agrochemicalMall/product/update.vue'),
        meta: { title: '修改商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallRecommend',
        name: 'mallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addMallRecommend',
        name: 'addMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallRecommend',
        name: 'updateMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallWarehouse',
        name: 'mallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/index.vue'),
        meta: { title: '仓库列表', icon: 'depot' }
      },
      {
        path: 'addMallWarehouse',
        name: 'addMallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/add.vue'),
        meta: { title: '添加仓库', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallWarehouse',
        name: 'updateMallWarehouse',
        component: () => import('@/views/agrochemicalMall/warehouse/update.vue'),
        meta: { title: '修改仓库', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallReportList',
        name: 'mallReportList',
        component: () => import('@/views/agrochemicalMall/reportList/index.vue'),
        meta: { title: '报表列表', icon: 'report' }
      },
      {
        path: 'mallReportDetail',
        name: 'mallReportDetail',
        component: () => import('@/views/agrochemicalMall/reportList/orderDetail.vue'),
        meta: { title: '报表详情', icon: 'report' },
        hidden: true
      },
    ]
  },
  // App管理
  {
    path: '/appManagement',
    component: Layout,
    redirect: '/appManagement/managementList',
    name: 'appManagement',
    meta: { title: 'App管理', icon: 'dangerous_goods' },
    children: [
      {
        path: 'managementList',
        name: 'managementList',
        component: () => import('@/views/appManagement/InformationManagement/index.vue'),
        meta: { title: 'App信息管理列表', icon: 'dangerous' }
      },
      {
        path: 'versionList',
        name: 'versionList',
        component: () => import('@/views/appManagement/version/index.vue'),
        meta: { title: 'App版本管理', icon: 'dangerous' }
      },
      {
        path: 'addVersion',
        name: 'addVersion',
        component: () => import('@/views/appManagement/version/add.vue'),
        meta: { title: '添加版本', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateVersion',
        name: 'updateVersion',
        component: () => import('@/views/appManagement/version/update.vue'),
        meta: { title: '修改版本', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

