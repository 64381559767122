import defaultImg from '../../assets/images/live/default.png'
import Cookies from "js-cookie";
import { Message } from 'element-ui'


// let defaultGroupId = ''
// let defaultTitle = ''
// let defaultUserInfo = {
//   userId:'',
//   userSig:''
// }
// try {
//   if (localStorage.groupId) {
//     defaultGroupId = localStorage.groupId
//   }
// } catch (e) {
//   console.log(e)
// }
// try {
//   if (localStorage.title) {
//     defaultTitle = localStorage.title
//   }
// } catch (e) {
//   console.log(e)
// }
// try {
//   if (localStorage.userInfo || localStorage.userSig) {
//     defaultUserInfo.userId = localStorage.userId,
//     defaultUserInfo.userSig = localStorage.userSig

//   }
// } catch (e) {
//   console.log(e)
// }
const live = {
    state: {
        currentUserProfile: {},
        isLogin: false,    // 是否在登录状态   目前是token为主
        isSDKReady: false, // TIM SDK 是否 ready
        userID: 0,
        userSig: '',
        sdkAppID: 1400333268,
        errorImg: 'this.src="' + require('../../assets/images/live/default.png') + '"', //加载图片报错时处理方法
        userInfo: {
            nickName: '',
            avatar: '',
            defaultImg: defaultImg
        },


        currentMessageList: [],
        currentLiveTips: [],
        liveDomain: '',
        playType: 'WebRTC',   //默认webRTC 播放，cdn
        showLogin: false, //是否展示登录
        fullPath: '',
        pushInfo: {
            playUrl: '',
            isPushing: false,
            title: '',
            resolution: '720p'
        },
        chatInfo: {
            groupId: '611111111',
            userId: '',
            userSig: '',
            sdkAppID: 1400333268,
            liveDomainName: 'pushv.chinaharlan.com',
            streamId: '',
            role: '',
            resolution: ''
        },
        likeCount: 0,     //点赞人数

        message: undefined,

    },
    mutations: {
        updateCurrentUserProfile(state, userProfile) {
            state.currentUserProfile = userProfile
        },
        toggleIsLogin(state, isLogin) {
            state.isLogin = typeof isLogin === 'undefined' ? !state.isLogin : isLogin
        },
        toggleIsSDKReady(state, isSDKReady) {
            state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
        },
        // reset(state) {
        //   Object.assign(state, {
        //     currentUserProfile: {},
        //     isLogin: false,   // 是否显示登录
        //     isSDKReady: false // TIM SDK 是否 ready
        //   })
        // },
        GET_USER_INFO(state, payload) {
            // console.log(2222);
            let backstage = JSON.parse(Cookies.get("backstage"))
            state.userID = backstage.harlanId
            state.userSig = backstage.userSig
            state.chatInfo.userSig = backstage.userSig
            state.chatInfo.userId = backstage.harlanId

            // state.sdkAppID = 1400333268
            //   state.userID = payload.userID
            //   state.userSig = payload.userSig
            //   state.sdkAppID = payload.sdkAppID

        },

        setRole(state, data) {
            state.chatInfo.role = data
        },
        setLiveDomain(state, data) {
            state.liveDomain = data
        },
        setPlayInfo(state, data) {
            state.pushInfo.playUrl = data
        },
        setIsPushing(state, data) {
            state.pushInfo.isPushing = data
        },
        setPusherInfo(state, data) {
            state.pushInfo.title = data.title
            state.pushInfo.resolution = data.resolution
            try {
                localStorage.title = data.title
            } catch (e) {
                console.log(e)
            }
        },
        setGroupId(state, data) {
            state.chatInfo.groupId = data

            try {
                localStorage.groupId = data
            } catch (e) {
                console.log(e)
            }
            // localStorage.setItem('userInfo', JSON.stringify(data))
            // 之后才是修改state中的状态
        },
        setPlayType(state, data) {
            state.playType = data
        },
        showLogin(state, data) {
            state.showLogin = data
        },
        setChatInfo(state, data) {
            // state.chatInfo.groupId = data.roomID

            state.chatInfo.userId = data.userID
            state.chatInfo.userSig = data.userSig
            state.chatInfo.resolution = data.resolution
            try {
                localStorage.userId = data.userID
                localStorage.userSig = data.userSig

            } catch (e) {
                console.log(e)
            }
        },
        showLike(state, data) {
            state.likeCount += data
        },
        /**
         * 将消息插入当前会话列表
         * 调用时机：收/发消息事件触发时
         * @param {Object} state
         * @param {Message[]|Message} data
         * @returns
         */
        pushCurrentMessageList(state, data) {
            if (Array.isArray(data)) {
                state.currentMessageList = [...state.currentMessageList, ...data]
            } else {
                state.currentMessageList = [...state.currentMessageList, data]
            }
        },
        pushCurrentTipsList(state, data) {
            let timer = null
            if (Array.isArray(data)) {
                state.currentLiveTips = [...state.currentLiveTips, ...data]
            } else {
                state.currentLiveTips = [...state.currentLiveTips, data]
            }
            timer = setTimeout(() => {
                state.currentLiveTips.shift()
            }, 2000)
            if (state.currentLiveTips.length === 0) {
                clearTimeout(timer)
            }
        },
        /**
         * 从当前消息列表中删除某条消息
         * @param {Object} state
         * @param {Message} message
         */
        reset(state) {
            Object.assign(state, {
                currentMessageList: [],
                currentLiveTips: [],
            })
        },

        showMessage(state, options) {
            if (state.message) {
                state.message.close()
            }
            state.message = Message({
                message: options.message,
                type: options.type || 'success',
                duration: options.duration || 2000, //2000
                offset: 40
            })
        },
    },
    actions: {
        // logout(context) {
        //   // 若有当前会话，在退出登录时已读上报
        //   if (context.rootState.conversation.currentConversation.conversationID) {
        //     tim.setMessageRead({ conversationID: context.rootState.conversation.currentConversation.conversationID })
        //   }
        //   tim.logout().then(() => {
        //     context.commit('toggleIsLogin')
        //     context.commit('stopComputeCurrent')
        //     context.commit('reset')
        //   })
        // }
        getUserInfo(context) {
            context.commit('GET_USER_INFO')
        }
    }
}

export default live
