<template>
  <div class="app-wrapper" :class="classObj">
    <div class="main-container">
      <app-main></app-main>
    </div>
  </div>
</template>

<script>
import { AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: "layout2",
  components: {
    AppMain,
  },
  data() {
    return {
      backstage: null, //用户信息
      rList: [],
    };
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
.app-main{
  // min-height: 1000px;
  margin-bottom: 160px;
  .main-container{
    margin-left: 0px !important;
  }
}
</style>
