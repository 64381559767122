import Vue from 'vue'

import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import '@/styles/index.scss' // global css

import App from './App'
import router from './router'
import store from './store'

import '@/icons' // icon
import '@/permission' // permission control

Vue.use(ElementUI, { locale })

import { ElementTiptapPlugin } from 'element-tiptap';
// 引入 ElementUI 样式
import 'element-ui/lib/theme-chalk/index.css';
// import element-tiptap 样式
import 'element-tiptap/lib/index.css';
// 安装 element-tiptap 插件
Vue.use(ElementTiptapPlugin, {
  lang: "zh", // 见 i18n
  spellcheck: true, // 可被 editor 同名 prop 重写
});


Vue.config.productionTip = false

//直播
import TWebLive from 'tweblive'
Vue.prototype.TWebLive = TWebLive


new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})
