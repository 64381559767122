<template>
    <div class="footers">
        <div class="type_area">
            <div class="bom">
                <p>
                    <span>Copyright 2017-2021 chinaharlan.com 化浪科技</span>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备17059278号-1</a>
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020502000290"
                        target="_blank"
                    >
                        <img src="../../assets/images/gongan.png" alt /> 浙公网安备
                        33020502000290号
                    </a>
                    | 增值电信业务经营许可证：浙B2-20190457
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "footers"
}
</script>

<style lang="less" scoped>
.footers {
    width: 100%;
    height: 44px;
    background: #3593b9;
    position: fixed;
    z-index: 1002;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .type_area {
        width: 1340px;
        height: 100%;
        margin: auto;
        .bom {
            // border-top: 1px solid #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            // margin-top: 20px;
            // padding: 20px 0 0;
            p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-bottom: 10px;
                // margin: 0;
                display: flex;
                align-items: center;
            }
            a {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                margin: 0 4px;
                img{
                    margin-right: 4px;
                }
            }
        }
    }
}
</style>