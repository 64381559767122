import request from '@/utils/request'

export function login(username, password) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: {
      username,
      password
    }
  })
}

export function getInfo() {
  return request({
    url: '/admin/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}

export function fetchList(params, menuId) {
  return request({
    url: '/admin/list/' + menuId,
    method: 'get',
    params: params
  })
}

export function createAdmin(data,menuId) {
  return request({
    url: '/admin/register/'+menuId,
    method: 'post',
    data: data
  })
}

export function updateAdmin(id, data, menuId) {
  return request({
    url: `/admin/update/${id}/${menuId}`,
    method: 'post',
    data: data
  })
}

export function updateStatus(id, params, menuId) {
  return request({
    url: `/admin/updateStatus/${id}/${menuId}`,
    method: 'post',
    params: params
  })
}

export function deleteAdmin(id,menuId) {
  return request({
    url: `/admin/delete/${id}/${menuId}`,
    method: 'post'
  })
}

export function getRoleByAdmin(id) {
  return request({
    url: '/admin/role/' + id,
    method: 'get'
  })
}

export function allocRole(data,menuId) {
  return request({
    url: '/admin/role/update/'+menuId,
    method: 'post',
    data: data
  })
}
