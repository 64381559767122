<template>
  <div class="app-wrapper" :class="classObj">
    <sidebar class="sidebar-container"></sidebar>
    <div class="main-container">
      <navbar></navbar>
      <app-main></app-main>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import footers from '../../components/footer'
// import TIM from "tim-js-sdk";
// import TIMUploadPlugin from "tim-upload-plugin";
// let options = {
//   SDKAppID: 1400333268, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
// };
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
// let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// 设置 SDK 日志输出级别，详细分级请参见 <a href="https://web.sdk.qcloud.com/im/doc/zh-cn//SDK.html#setLogLevel">setLogLevel 接口的说明</a>
// tim.setLogLevel(4); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
// 注册腾讯云即时通信 IM 上传插件
// tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
import Cookies from "js-cookie";

export default {
  name: "layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    footers
  },
  data() {
    return {
      backstage: null, //用户信息
      rList: [],
    };
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    logData(userID, userSig) {
      const self = this;
      let promise = tim.login({ userID: userID, userSig: userSig });
      promise
        .then(function (imResponse) {
          //获取会话列表
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            console.log(imResponse.data.errorInfo);
          }
        })
        .catch(function (imError) {
          console.warn("login error:", imError); // 登录失败的相关信息
        });

      // 监听事件
      tim.on(TIM.EVENT.SDK_READY, function (event) {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        self.hlData();
      });

      tim.on(TIM.EVENT.MESSAGE_RECEIVED, function (event) {
        console.log("event", event.data);
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        self.hlData();
      });
    },
    hlData() {
      const self = this;
      let promise = tim.getConversationList();
      promise
        .then(function (imResponse) {
          console.log("imResponse", imResponse);
          self.rList = imResponse.data.conversationList;
        })
        .catch(function (imError) {
          console.log(imError);
        });
    },
  },
  created() {
    // this.backstage = JSON.parse(Cookies.get("backstage"));
    // this.logData(this.backstage.harlanId, this.backstage.userSig);
  },
  destroyed() {
    // 离开页面退出当前账号
    // let promise = tim.logout();
    // promise
    //   .then(function (imResponse) {
    //     console.log("退出成功", imResponse.data);
    //   })
    //   .catch(function (imError) {
    //     console.warn("logout error:", imError);
    //   });
  },
  watch: {
    rList: {
      //深度监听，可监听到对象、数组的变化
      handler(newVal, oldVal) {
        let count = 0;
        for (let i = 0; i < newVal.length; i++) {
          count += newVal[i].unreadCount;
        }
        if (count > 0) {
          document.title = `(${count}) 化浪阿卡丹管理系统`;
        } else {
          document.title = `化浪阿卡丹管理系统`;
        }
      },
      deep: true, //true 深度监听
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
.app-main{
  // min-height: 1000px;
  margin-bottom: 160px;
}
</style>
